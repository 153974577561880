
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import isEqual from 'fast-deep-equal/es6';
import { AgentboxDomainData, BotDomain, DeepPartial, DomainData } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';

type AgentboxSettings = Pick<AgentboxDomainData, 'apiKey' | 'clientId'>;

@Component({
  name: 'agentbox-settings-card',
})
export default class AgentboxSettingsCard extends mixins(BotMixin) {
  agentboxSettings: AgentboxSettings = this.resetValue();

  isSubmitting = false;

  get settingsHaveChanged(): boolean {
    return !isEqual(this.agentboxSettings, this.resetValue());
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      await this.$updateBot({
        id: this.bot.id,
        data: {
          domainData: {
            [BotDomain.RealEstate]: {
              agentbox: this.agentboxSettings,
            },
          } as DeepPartial<DomainData>,
        },
      });
      this.$notify.success('Successfully updated Agentbox-settings');
    } catch (e) {
      this.$notify.error({
        title: 'Updating Agentbox-settings failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  @Watch('bot', { immediate: true, deep: true })
  private reset() {
    this.agentboxSettings = this.resetValue();
  }

  private resetValue(): AgentboxSettings {
    return {
      apiKey: this.bot.domainData[BotDomain.RealEstate]?.agentbox?.apiKey || '',
      clientId: this.bot.domainData[BotDomain.RealEstate]?.agentbox?.clientId || '',
    };
  }
}
