
import AgentboxSettingsCard from '@/components/real-estate/AgentboxSettingsCard.vue';
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'project-real-estate-agentbox',
  components: { AgentboxSettingsCard },
})
export default class ProjectRealEstateAgentbox extends mixins(ProjectViewMixin) {}
